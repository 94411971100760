<template>
  <v-card>
    <v-card-title><span style="font-size:2rem; font-weight: bold; letter-spacing: 2.5px">{{course.course.title}}</span></v-card-title>

   <v-card-subtitle><small v-if="section">Sektion {{section.id}} : {{ section.title }}</small></v-card-subtitle>
    <v-card-text>
  <div class="mb-5" v-if="notStarted">
    <v-card-title class="text-left" v-html="course.course.description"></v-card-title>
    <v-btn size="x-large" block color="accent" class="mt-2" @click="startCourse">Jetzt starten</v-btn>
  </div>
  <div v-else class="mb-5">
<v-tabs v-model="tab">
  <v-tab value="video">Video</v-tab>
  <v-tab value="test">Test</v-tab>
  <v-spacer/>
  <small class="align-self-center text-disabled">{{course.course.test.questions.length}} Fragen</small>
</v-tabs>

    <v-window v-model="tab">
      <v-window-item
          value="video"
      >
        <div v-if="course && course?.course && course?.course?.videoUrl">

        <YoutubePlayer   :video-url="course?.course?.videoUrl"/>
        </div>
        <!--<video class="my-5 rounded-xl" v-if="course?.course?.video" style="width:100%" playsinline controls>
          <source :src="'data:video/mp4;base64,'+course.course.video" type="video/mp4">
          Your browser does not support the video tag.
        </video>-->
        <v-sheet class="my-5 rounded-xl d-flex justify-center align-center" min-height="300px" v-else color="blue">
          Kein Video vorhanden.
        </v-sheet>
      </v-window-item>
      <v-window-item
          value="test"
      >
        <CourseTest :admin="admin" :course="course"/>
      </v-window-item>
    </v-window>
    <v-divider/>
  </div>
    </v-card-text>
    <v-card-actions>
      <v-btn type="text" @click="toggleDialog(false)" block>Schließen</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CourseTest from "@/components/courses/CourseTest";
import {startCourse} from "@/services/courses.service";
import {useCoursesStore} from "@/stores/courses.module";
//import 'video.js/dist/video-js.css'
import YoutubePlayer from "@/components/YoutubePlayer";
export default {
  name: "CourseSite",
  components: {YoutubePlayer, CourseTest},
  setup(){
    const coursesStore = useCoursesStore();
    return {
      coursesStore
    }
  },
  data(){
    return{
      tab:'video',
    }
  },
  computed:{
    notStarted(){
      if(this.admin) return false;else if(this.course.state==='NOT_STARTED') return true;
      else return false;
    }
  },
  methods:{
    toggleDialog(value){
      this.$emit("toggleDialog",value)
    },
    async startCourse() {
      let result = await startCourse(this.section.id, this.course.course.id);
      if(result==='IN_PROGRESS') this.coursesStore.findAllUserCoursesBySection(this.section.id);
    }
  },
  props:{
    show:Boolean,
    admin:Boolean,
    section:Object,
    course:Object
  }
}
</script>

<style scoped>

</style>