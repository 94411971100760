<template>
  <v-snackbar
      class="animate__animated animate__slideInDown"
      location="top"
      v-model="snackbar"
      :timeout="timeout"
      :color="color"
      multi-line
  >
    <p style="width:100%;font-size: 1rem;" v-if="notification.title">{{notification.title}}</p>
    <p style="width:100%" v-if="notification.message">{{notification.message}}</p>


    <template v-slot:actions>

      <v-btn
          v-if="notification.link"
          variant="plain"
          :href="notification.link"
          icon="mdi-open-in-app">
      </v-btn>
      <v-btn
          color="blue"
          variant="plain"
          @click="snackbar = false"
          icon="mdi-close">
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "NotificationSnackbar",
  data(){
    return{
      snackbar:true,
      timeout:20000
    }
  },
  computed:{
    color(){
      switch(this.notification.color){
        case 'ERROR': return 'error';
        case 'SUCCESS': return 'success';
        case 'INFO':return 'info';
        case 'WARNING': return 'warning';
        default: return null;
      }
    }
  },
  props:{
    notification:Object
  }
}
</script>

<style scoped>

</style>