<template>
<v-app-bar v-if="$route.name!='Seite'" class="app-bar bg-primary" elevation="0" app>
  <template v-slot:prepend>
    <div class="py-2" v-if="$route.meta && $route.meta.appBarBackButton">
      <v-btn icon="mdi-chevron-left" @click="$router.back()"></v-btn>
    </div>
    <div class="pl-1" >
      <div style="width: 150px">
        <ProgressBarSmall/>
      </div>
    </div>

  </template>
  <template v-slot:append>
    <v-tooltip v-if="!serverState" text="Keine Verbindung zum Server" location="bottom">
      <template v-slot:activator="{props}">
        <v-btn @click="refresh" v-bind="props" color="error" icon="mdi-cloud-off"></v-btn>
      </template>

    </v-tooltip>
    <v-btn icon @click="$router.push('/fortschritt')"><v-badge v-if="unclaimedRewards.length>0" :content="unclaimedRewards.length" color="accent"><v-icon icon="mdi-gift"></v-icon></v-badge><v-icon v-else icon="mdi-gift"></v-icon></v-btn>

      <v-btn icon @click="toggleSettingsmenu"><v-icon icon="mdi-dots-vertical"></v-icon></v-btn>

  </template>
</v-app-bar>
</template>

<script>
import ProgressBarSmall from "@/components/progress/ProgressBarSmall";
import {useAppStore} from "@/stores/app.module";
import {useAuthUserStore} from "@/stores/modules/auth.module";
import {useRewardStore} from "@/stores/reward.module";
import {useStatusStore} from "@/stores/status.module";
export default {
  name: "TopNavigationNew",
  components: { ProgressBarSmall},
  setup(){
    const statusStore = useStatusStore();
    const appStore = useAppStore();
    const authStore = useAuthUserStore();
    const rewardStore = useRewardStore();
    rewardStore.getMyRewards();

    return {
      statusStore,
      appStore,
      authStore,
      rewardStore
    }
  },
  computed:{

    serverState(){
      return this.statusStore.server;
    },

    unclaimedRewards(){
      return this.rewardStore.rewards.filter(x=> !x.claimed);
    },

    currentUser(){
      return this.authStore.user;
    },
    greeting(){
      let moment = require('moment');

      let time = moment().format("HH");
      if(time>=4 && time<12 ) return "Guten Morgen, "
      else if(time>=12 && time<13) return "Mahlzeit, "
      else if(time>=18 && time<23) return "Guten Abend, "
      else return "Guten Tag, "
    }
  },
  methods:{
    refresh(){
      location.href="/";
    },
    toggleSettingsmenu(){
      this.appStore.toggleBottomSettings();
    }
  }
}
</script>

<style scoped>

</style>