<template>

  <v-tabs
      v-model="tab"
  >
    <v-tab value="pending">Pending</v-tab>
    <v-tab value="error">Error</v-tab>
    <v-tab value="done">Done</v-tab>
  </v-tabs>

    <v-window v-model="tab">
      <v-window-item value="pending">
        <payout-item v-for="(payout,$index) of pending" :key="$index" :payout="payout"/>
      </v-window-item>

      <v-window-item value="error">
        Two
      </v-window-item>

      <v-window-item value="done">
        Three
      </v-window-item>
    </v-window>

</template>

<script>
import {getAllPendingPayouts} from "@/services/admin.service";
import PayoutItem from "@/components/admin/payout/PayoutItem";

export default {
  name: "PayoutWrapper",
  components: {PayoutItem},
  data(){
    return{
      pending:null,
      tab:"pending"
    }
  },
  created() {
    this.getAllPendingPayouts();
  },
  methods:{
    async getAllPendingPayouts() {
      this.pending = await getAllPendingPayouts();
    }
  }
}
</script>

<style scoped>

</style>