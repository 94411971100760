import Compressor from "compressorjs";
import ApiService from "@/services/api.service";
import {blobToBase64, dataURItoBlob} from "@/services/image.service";
import {useAuthUserStore} from "@/stores/modules/auth.module";

class UserService {
    async getFreeSlots(){
        const response = await ApiService.get("user/slots/free")
        return response.data;
    }
    async buySlotWithToken(){
        const response = await ApiService.get("user/slots/buy/token");
        return response.data;
    }
    async getUser(id){
        const response = await ApiService.get("user/get?id="+id);
        return response.data;
    }
    async getUserFromLink(link){
      const response = await ApiService.postUnauthorized("user/hash/get",{payload:link});
        return response.data;
    }
    async getProfileLink(){
     const response = await ApiService.get("user/share/get");
     return response.data;
    }
    async regenerateProfileLink(profileLink){
        const response = await ApiService.post("user/share/regenerate",profileLink);
        return response.data;
    }
    async getAddresses() {
        const response = await ApiService.get("user/address/find");
        return response.data;
    }
    async saveAddress(address) {
        const response = await ApiService.post("user/address/save", address);
        return response.data;
    }
    async saveBankDetails(request){
        const response = await ApiService.post("user/bankdetails/save",request);
        return response.data
    }
    async getBankDetails(){
        const response = await ApiService.get("user/bankdetails/get");
        return response.data;
    }
     compressAndUploadProfilePicture(file){
        const blob = dataURItoBlob(file)
        new Compressor(blob, {
            quality: 0.6,

            // The compression process is asynchronous,
            // which means you have to access the `result` in the `success` hook function.
            async success(result) {
                const userStore = useAuthUserStore();
                let base64String = await blobToBase64(result);

                base64String=base64String.replace("data:","");
                base64String=base64String.split(";")[1];
                base64String=base64String.split(",")[1];
                ApiService.post("user/avatar/save", {avatar:base64String}).then(response => {
                    userStore.updateLoggedInUser(response.data)

                });

            },
            error(err) {
                console.log(err.message);
            },
        });
    }
}

export default new UserService();