import { createApp } from 'vue'
import App from './App.vue'
import '@/style/global.scss';
import Trend from 'vue3trend'
import VueShepherdPlugin from 'vue-shepherd';
import 'shepherd.js/dist/css/shepherd.css';
//Store
import { createPinia } from 'pinia'
const pinia = createPinia();

// Vuetify
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'

import { VDatePicker } from 'vuetify/labs/VDatePicker';
import * as directives from 'vuetify/directives'
// make sure to also import the coresponding css
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

//Router
import router from './router'




import 'animate.css';


import {VSkeletonLoader} from "vuetify/labs/VSkeletonLoader";

import './registerServiceWorker'
const vuetify = createVuetify({
    options: {
        customProperties: true
    },
    defaults: {
        VBtn: {
            class: 'rounded-xl',
            style: [{ textTransform: 'none' }],
        },
    },
    theme: {
        defaultTheme: 'dark',
        themes: {
            dark: {
                dark: true,
               colors: {
                    primary: '#282828', //#B5CFF8  // #E53935           //#1b1b1b
                    secondary: '#3D3E40',  // #FFCDD2                   //#313131
                     accent:'#CCA876',
                    blue:'#3D4C62', //
                    'blue-darken-1':'304159',
                    grey:'#CBC9D4',
                    darkblue:'#130160'
                }
               /* colors: {
                    primary: '#1b1b1b', //#B5CFF8  // #E53935           //#1b1b1b
                    secondary: '#313131',  // #FFCDD2                   //#313131
                     accent:'#CCA876',
                    blue:'#b5cff8', //
                    'blue-darken-1':'304159',
                    grey:'#CBC9D4',
                    darkblue:'#130160'
                }*/
            },
        },
    },
    components:{
        ...components,
        VSkeletonLoader,
        VDatePicker

    },
    directives,
});


createApp(App).use(VueShepherdPlugin).use(Trend).use(pinia).use(router).use(vuetify).mount('#app')
