<template>
  <v-expansion-panels>
    <v-expansion-panel

    >
      <template #title>
        <v-list-item title="Objekt Eigentümer">
          <template #subtitle>
            <small v-if="errorMessage" class="text-error">{{errorMessage}}</small>
          </template>
        </v-list-item>
      </template>
      <template #text>
        <v-text-field :disabled="disabledP" v-model="ownerReactive.fullName" label="Voller Name des Eigentümers" class="my-2" hint="Bitte denke daran, dass es nicht gestattet ist, diese Daten ohne die Einwilligung des Eigentümers anzugeben."  placeholder="Voller Name des Eigentümers"></v-text-field>
        <v-text-field :disabled="disabledP" v-model="ownerReactive.email" label="Email des Eigentümers" class="my-2"  placeholder="Email des Eigentümers"></v-text-field>
        <v-text-field :disabled="disabledP" v-model="ownerReactive.telephone" label="Telefonnummer des Eigentümers" class="my-2 "  placeholder="Email des Eigentümers"></v-text-field>

      </template>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "EditObjectOwner",
  props:{
    owner:Object,
    disabledP:Boolean
  },
  computed:{
    errorMessage(){
      if(!this.ownerReactive.fullName && !this.ownerReactive.email && !this.ownerReactive.telephone){
        return "Es fehlen wichtige Daten!"
      }
      else if(!this.ownerReactive.fullName) return "Der Name des Eigentümers fehlt."
      else if(!this.ownerReactive.email) return "Die Email des Eigentümers fehlt."
      else if(!this.ownerReactive.telephone) return "Die Telefonnummer des Eigentümers fehlt."
      else return null;
    },
    ownerReactive:{
      get(){
        return this.owner;
      },set(value){
        this.$emit("updateOwner",value);
      }
    }
  }
}
</script>

<style scoped>

</style>