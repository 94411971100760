<template>
  <swiper
      :slidesPerView="'auto'"
      :spaceBetween="10"
      :freeMode="true"
      :modules="modules"
  >
    <swiper-slide style="width: 300px" v-for="object of myObjects" :key="object.id">
    <ObjectDisplaySmall :object="object"/></swiper-slide>
    <swiper-slide style="width:250px" v-for="slide of freeSlots" :key="slide+'free'">
      <v-card color="blue" style="border-radius: 20px;" class="pa-5 d-flex flex-wrap" width="80%" height="150px" elevation="0">
        <v-img cover style="position:absolute;top:0;left:0;width:100%;height:100%;opacity:0.1;z-index:0" :src="require('@/assets/'+image(slide))"></v-img>
        <div style="width: 100%;z-index:1" class="text-white text-left">Freier Slot</div>
        <div style="z-index:1" class="d-flex align-end justify-start">
          <v-btn variant="tonal" @click="$router.push('/immobilie/erstellen')" color="accent" block size="small">Immobilie anlegen</v-btn>

        </div>
      </v-card>
    </swiper-slide>
    <swiper-slide style="width:250px" :key="'locked'">
      <v-card style="border-radius: 20px;" class="pa-5 d-flex flex-wrap justify-space-between" color="blue" width="80%" height="150px" elevation="0">

        <div class="text-white text-left">Gesperrter Slot <v-icon size="x-small" icon="mdi-lock"></v-icon></div>
        <div class="d-flex align-end">
          <v-btn variant="tonal" color="success" block @click="buySlot" size="small">Slot freischalten</v-btn>

        </div>
      </v-card>
    </swiper-slide>

  </swiper>
  <!--<v-slide-group v-model="active" center-active>
    <v-slide-group-item v-for="object of myObjects" :value="object.id" :key="object.id">
      <ObjectDisplaySmall :object="object" class="mx-2"/>
    </v-slide-group-item>
    <v-slide-group-item  v-for="slide of 2" :value="slide+'free'" :key="slide+'free'">
      <v-card color="blue" style="border-radius: 20px" class="pa-5 mr-2 d-flex flex-wrap" width="80vw" height="150px" elevation="2">
        <div style="width: 100%" class="text-white text-left">freier Slot</div>
        <div class="d-flex align-end justify-start">
          <v-btn variant="tonal" @click="$router.push('/immobilie/erstellen')" color="white" block size="small">Immobilie anlegen</v-btn>

        </div>
      </v-card>
    </v-slide-group-item>
    <v-slide-group-item v-for="slide in 1" :value="slide+'locked'" :key="slide+'locked'">
      <v-card class="pa-5  mr-2  d-flex flex-wrap justify-space-between" color="blue" width="80vw" height="150px" elevation="2">
        <div class="text-white text-left">gesperrter Slot <v-icon size="x-small" icon="mdi-lock"></v-icon></div>
        <div class="d-flex align-end">
          <v-btn variant="tonal" color="white" block size="small">Slot freischalten</v-btn>

        </div>
      </v-card>
    </v-slide-group-item>

  </v-slide-group>-->
</template>

<script>
import { defineComponent } from 'vue'
import ObjectDisplaySmall from "@/components/objects/ObjectDisplaySmall";
import {useObjectStore} from "@/stores/objects.module";
import {Swiper, SwiperSlide } from 'swiper/vue';
import{FreeMode} from 'swiper';

// Import Swiper styles
import 'swiper/css';
import {useUserStore} from "@/stores/user.module";
export default defineComponent({
  name: 'ObjectCaroussel',
  components: {
    ObjectDisplaySmall,
    Swiper,
    SwiperSlide,
  },
  setup(){
    const objectStore = useObjectStore();
    const userStore = useUserStore();
    objectStore.getMyObjects();
    return{
      objectStore,
      userStore
    }
  },
  watch:{
    myObjects(){
      this.userStore.getMyFreeSlots();
    }
  },
  computed:{
    freeSlots(){
      return this.userStore.currentUser.freeSlots;
    },
    myObjects(){
      return this.objectStore.myObjects;
    }
  },
  methods: {
    image(index){
      index--;
      if(index>=this.images.length){
        if(index%2===0) return this.images[0];
        else return this.images[1];
      }
      return this.images[index];
    },
    buySlot(){
      if (confirm("Mit Klick auf OK werden dir 1000 Token abgezogen & 1 Slot hinzugefügt") == true) {
        this.userStore.buySlotWithToken();
      } else {
        return null;
      }
    },
    onSwiper: function () {
      console.log("onSwiper called");
    },
    onSlideChange: function () {
      console.log("Slide change");
    },
  },
  data(){
    return{
      active:null,
      modules:[FreeMode],
      images:[
          "alexander-andrews-A3DPhhAL6Zg-unsplash.jpg",
          "brandon-griggs-wR11KBaB86U-unsplash.jpg",
          "etienne-beauregard-riverin-B0aCvAVSX8E-unsplash.jpg",
      ]
    }
  }
})
</script>
