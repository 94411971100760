<template>
  <v-dialog>
    <template #activator="{props}">
      <v-list-item v-bind="props" :title="-payout.requestedAmount+' | '+user.firstName+' '+user.lastName" :subtitle="bankDetails.iban" :prepend-avatar="user.id">

      </v-list-item>
    </template>
    <v-card>
      <v-card-subtitle>Betreff: Auszahlung EstateBoost an {{user.firstName}} {{user.lastName}} | TransaktionID {{transaction}} </v-card-subtitle>

      <v-card-subtitle>Betrag: {{-payout.requestedAmount}}</v-card-subtitle>

      <CardView :bank-details="bankDetails"/>
    </v-card>
  </v-dialog>

</template>

<script>

import CardView from "@/components/user/CardView";
export default {
  name: "PayoutItem",
  components: {CardView},
  props:{
    payout:Object
  },
  computed:{
    user(){
      return this.payout.user;
    },
    bankDetails(){
      return this.payout.bankDetails;
    },
    transaction(){
      return this.payout.transaction;
    }
  }
}
</script>

<style scoped>

</style>